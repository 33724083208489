<template>
  <form class="form" :class="{ mobile: isMobile }" data-t="change-email-form">
    <StDrawerHeader
      v-if="isMobile"
      :with-back-button="isLast"
      class="form-header"
      :title="t('changeEmail.title')"
      :data-t="{ root: 'tabs', title: 'change-email-form-title' }"
      @back="handleSecondStepGoBack"
    />
    <div class="form-wrapper">
      <KeepAlive>
        <FirstStepChangeEmail
          v-if="isCurrent(0)"
          @success="handleFirstStepSuccess"
        />
        <SecondStepChangeEmail
          v-else-if="isCurrent(1) && firstStepData"
          :first-step-data="firstStepData"
          @back="handleSecondStepGoBack"
          @success="successFormClose"
        />
      </KeepAlive>
    </div>
  </form>
</template>

<script setup lang="ts">
import { useUserStore } from '@st/user/stores/useUserStore'
import FirstStepChangeEmail from './parts/FirstStepChangeEmail.vue'
import SecondStepChangeEmail from './parts/SecondStepChangeEmail.vue'

interface FirstStepData {
  emailOld: string
  email: string
  password: string
  isCodeSent: boolean
}
const userStore = useUserStore()
const { refreshUserData } = userStore
const { t } = useI18n()
const { isMobile } = usePlatform()
const { isCurrent, goToNext, goToPrevious, isLast } = useStepper([0, 1])
const router = useRouter()
const toast = useToast()

const firstStepData = ref<FirstStepData>()

function handleFirstStepSuccess(data: FirstStepData) {
  goToNext()
  const { email, emailOld, password } = data
  firstStepData.value = {
    emailOld,
    email,
    password,
    isCodeSent: true,
  }
}
function successFormClose() {
  toast.open({
    label: t('changeEmail.success'),
    type: 'positive',
  })
  router.replace({ query: {} })
  refreshUserData()
}
function handleSecondStepGoBack() {
  goToPrevious()
}
</script>

<style scoped>
.form-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-200);
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  width: 480px;
  padding: var(--spacing-500) var(--spacing-600) var(--spacing-500)
    var(--spacing-600);

  &.mobile {
    width: 100%;
    padding: 0;
    padding-top: var(--spacing-250);
    padding-bottom: var(--spacing-200);

    .title {
      font: var(--mobile-title-2-semibold);
    }

    .form-wrapper {
      gap: var(--spacing-200);
      padding: 0 var(--spacing-200);
    }

    .form-header {
      padding-right: var(--spacing-100);
      padding-left: var(--spacing-200);
    }

    .submit-button {
      margin-top: auto;
    }
  }
}
</style>
