<template>
  <div v-if="!isMobile" class="form-header">
    <h2 class="title" data-t="change-email-form-title">
      {{ t('changeEmail.title') }}
    </h2>
  </div>
  <StInput
    v-bind="emailOld.componentBindings"
    :label="t('changeEmail.currentEmailLabel')"
    :placeholder="t('changeEmail.currentEmailPlaceholder')"
    type="email"
    :size="isMobile ? 'm' : 'l'"
    data-t="current-email"
  />
  <StInput
    v-bind="email.componentBindings"
    :label="t('changeEmail.newEmailLabel')"
    :placeholder="t('changeEmail.newEmailPlaceholder')"
    type="email"
    :size="isMobile ? 'm' : 'l'"
    data-t="new-email"
  />
  <StInputPassword
    v-bind="password.componentBindings"
    :label="t('changeEmail.passwordLabel')"
    :placeholder="t('changeEmail.passwordPlaceholder')"
    type="password"
    :size="isMobile ? 'm' : 'l'"
    data-t="password"
  />
  <StButton
    :label="t('changeEmail.submit')"
    :size="isMobile ? 'l' : 'xl'"
    data-t="submit-change-email-button"
    :loading="requestStatus === 'pending'"
    class="submit-button"
    :disabled="isDisabledSubmit"
    @click="handleSubmit"
  />
</template>

<script setup lang="ts">
import { required, useForm, validEmail } from '@st/validate'

const { t, locale } = useI18n()
const { isMobile } = usePlatform()
const { getToken, isLoadingRecaptcha, recaptchaQueryParam } = useRecaptcha()
const toast = useToast()

const emit = defineEmits<{
  (
    e: 'success',
    payload: {
      email: string
      password: string
      isCodeSent: boolean
      emailOld: string
    },
  ): void
}>()

const {
  fields: { emailOld, email, password },
  values,
  isValid,
} = useForm({
  fieldsSchema: {
    emailOld: {
      initialValue: '',
      validators: [
        {
          rule: required,
          triggerErrorDisplay: 'never',
        },
        {
          rule: validEmail,
          errorMessage: t('registration.errorMessages.emailShouldBeValid'),
        },
      ],
    },
    email: {
      initialValue: '',
      validators: [
        {
          rule: required,
          triggerErrorDisplay: 'never',
        },
        {
          rule: validEmail,
          errorMessage: t('registration.errorMessages.emailShouldBeValid'),
        },
      ],
    },
    password: {
      initialValue: '',
      validators: [
        {
          rule: required,
          triggerErrorDisplay: 'never',
        },
      ],
    },
  },
})

const isDisabledSubmit = computed(
  () => !isValid.value || isLoadingRecaptcha.value,
)

const requestCodeSendBody = computed(() => ({
  ...values.value,
  language: locale.value as any,
}))

const {
  execute,
  error,
  status: requestStatus,
} = useStFetch('/user/email/set/code/send', {
  method: 'post',
  body: requestCodeSendBody,
  immediate: false,
  watch: false,
  query: recaptchaQueryParam,
})

const errorMessage = computed(() => {
  const errorBody = error.value?.data
  if (!errorBody) return undefined

  const { error: errorCode } = errorBody as {
    error:
      | 'RECAPTCHA_IS_REQUIRED'
      | 'RECAPTCHA_IS_INVALID'
      | 'VALIDATION_ERROR'
      | 'RECAPTCHA_REQUEST_ERROR'
      | 'CONFIRMATION_CODE_RESEND_TIMEOUT'
      | 'FORBIDDEN_COUNTRY'
      | 'USER_UNAUTHORIZED'
      | 'CONFIRMATION_CODE_RATE_LIMIT'
      | 'USER_EMAIL_ALREADY_SET'
      | 'USER_WRONG_OLD_EMAIL'
      | 'USER_INVALID_PASSWORD'
  }

  switch (errorCode) {
    case 'CONFIRMATION_CODE_RATE_LIMIT':
      return t('changeEmail.errors.rateLimitExceeded')
    case 'CONFIRMATION_CODE_RESEND_TIMEOUT':
      return t('changeEmail.errors.resendTimeout')
    case 'USER_EMAIL_ALREADY_SET':
      return t('changeEmail.errors.emailAlreadySet')
    case 'USER_WRONG_OLD_EMAIL':
      return t('changeEmail.errors.wrongOldEmail')
    case 'RECAPTCHA_IS_REQUIRED':
      return t('changeEmail.errors.recaptchaRequired')
    case 'RECAPTCHA_IS_INVALID':
      return t('changeEmail.errors.recaptchaInvalid')
    case 'VALIDATION_ERROR':
      return t('changeEmail.errors.validation')
    case 'USER_INVALID_PASSWORD':
      return t('changeEmail.errors.invalidPassword')
    default:
      return t('changeEmail.errors.somethingWentWrong')
  }
})

async function handleSubmit() {
  await getToken('/user/email/set/code/send')

  await execute()
  if (
    !error.value ||
    error.value?.data?.error === 'CONFIRMATION_CODE_RESEND_TIMEOUT'
  ) {
    emit('success', {
      ...requestCodeSendBody.value,
      isCodeSent: !error.value,
    })
  } else {
    console.error('error:', error)
    toast.open({
      label: errorMessage.value ?? '',
      type: 'negative',
    })
  }
}
</script>

<style scoped>
.submit-button {
  margin-top: var(--spacing-100);
}

.title {
  margin: 0;
  font: var(--desktop-text-xl-semibold);
}
</style>
